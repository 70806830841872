<template>
  <div class="headerdiv">
    <headerderail :headertitle="headertitle" :backshow="backshow"></headerderail>
  </div>
</template>

<script>
    import headerderail from 'cp/v-header/headerdetail.vue'
    export default {
      name: 'userList',
      components: {
        headerderail
      },
      data () {
        return {
          backshow: true,
          headertitle: '会员信息'
        }
      }
    }
</script>

<style scoped>

</style>
